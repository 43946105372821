import React from 'react';
import { Col } from 'react-bootstrap';
import * as Styled from './multipleSVGLinksListStyles';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, INLINES } from '@contentful/rich-text-types';
import {
  ParaText,
  HeadingH4,
  UnorderedList,
  ListItem,
  ButtonStyle,
  ImageStyle,
} from '../../styles';
import useContentfulAssets from '../../hooks/useContentfulAssets';
import isExternalUrl, { addTrailingSlash, isExternalUrlHref } from '../../utils';

const MultipleSVGLinksList = ({ sectionData }) => {
  const optionsMainStyle = {
    renderNode: {
      [BLOCKS.HEADING_2]: (node, children) => (
        <Styled.HeaderH2 textAlign="left">{children}</Styled.HeaderH2>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Styled.HeaderH3 textAlign="left">{children}</Styled.HeaderH3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => <HeadingH4>{children}</HeadingH4>,
      [BLOCKS.PARAGRAPH]: (node, children) => <ParaText>{children}</ParaText>,
      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList>{children}</UnorderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
      [INLINES.HYPERLINK]: (node, children) => (
        <ButtonStyle
        href={isExternalUrlHref(node?.data?.uri)? node?.data?.uri :addTrailingSlash(process.env.GATSBY_DOMAIN_URL + node?.data?.uri)}
        target={isExternalUrl(node?.data?.uri) ? '_blank' : '_self'}
        >
          {children}
        </ButtonStyle>
      ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const asset = useContentfulAssets(node?.data?.target?.sys?.id);
        return asset?.node?.file?.url?.includes('image') ? (
          <ImageStyle src={asset?.node?.file?.url} alt={asset?.node?.title} />
        ) : (
          <></>
        );
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
  };
  return (
    <Styled.MainContainer>
      {sectionData?.header?.trim() && (
        <Styled.HeaderH2>{sectionData?.header}</Styled.HeaderH2>
      )}
      {sectionData?.subText?.trim() && (
        <Styled.HeaderH3>{sectionData?.subText}</Styled.HeaderH3>
      )}
      <Col>
        {sectionData?.contentDetails &&
          documentToReactComponents(
            JSON.parse(sectionData?.contentDetails?.raw),
            optionsMainStyle
          )}
      </Col>
    </Styled.MainContainer>
  );
};
export default MultipleSVGLinksList;
